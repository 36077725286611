// @flow
import styled, { css } from "styled-components";
import { RichText } from "prismic-reactjs";
import { ButtonLink } from "@nested/component-library";

import {
  sectionHeading,
  smallHeading,
} from "../../components/Typography/Headings";
import { media } from "../../media";

const background = css`
  background-color: ${({ backgroundColour, theme }) =>
    theme.palette[backgroundColour]};
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  max-width: 1140px;
  width: 100%;

  ${media.desktop`
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    padding: 50px 20px;
  `}
`;

const text = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    margin: 0;
  }

  ${media.desktop`
    align-items: flex-start;
  `}
`;

const Header = styled.h2`
  ${sectionHeading}
  margin: 0;
  text-align: center;
  ${media.desktop`
    text-align: left;
  `}
`;

const Content = styled.h3`
  ${smallHeading}
  color: ${({ backgroundColour, theme }) =>
    backgroundColour === "Hague" ? "white" : theme.palette.hague};
  font-family: ${({ theme }) => theme.fonts.euclid};
  font-weight: 400;
  margin: 10px 0 0 0;
  text-align: center;

  strong {
    font-weight: 500;
  }

  a {
    text-decoration: none;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }

  ${media.tablet`
    max-width: none;
  `}
  ${media.desktop`
    text-align: left;
  `}
`;

const button = css`
  margin-top: 20px;
  min-width: max-content;

  ${media.desktop`
    margin: 0;
  `}
`;

type Props = {
  backgroundColour: string,
  header: string,
  content: string,
  highlightedContent: string,
  ctaText: string,
  ctaLink: PrismicLink,
  bannerRichText: PrismicRichText,
};

export const Banner = ({
  backgroundColour = "yellow",
  header,
  content,
  highlightedContent,
  ctaText,
  ctaLink,
  bannerRichText,
}: Props) => {
  const hasBannerRichText =
    bannerRichText &&
    Array.isArray(bannerRichText) &&
    bannerRichText
      .filter((paragraph) => paragraph.text)
      .some((paragraph) => paragraph.text);

  return (
    <div
      backgroundColour={`${backgroundColour.toLowerCase()}100`}
      css={background}
    >
      <Wrapper>
        <div css={text}>
          {header && <Header>{header}</Header>}
          <Content>
            {hasBannerRichText ? RichText.render(bannerRichText) : content}
            {highlightedContent && !hasBannerRichText && (
              <strong>{highlightedContent}</strong>
            )}
          </Content>
        </div>
        {ctaText && ctaLink.url && (
          <div css={button}>
            <ButtonLink type="white" to={ctaLink.url} target={ctaLink?.target}>
              {ctaText}
            </ButtonLink>
          </div>
        )}
      </Wrapper>
    </div>
  );
};
